import {
    APPOINTMENTS,
    APPOINTMENTS_ERROR,
    CONTACTS,
    CONTACTS_ERROR,
    NEWSLETTER_SUBS,
    NEWSLETTER_SUBS_ERROR,
    RX_SENT,
    TOGGLE_APPOINTMENTS_LOADING
} from "../actions/types";

const initialState = {
    loading: false,
    contacts: {
        list: [],
        loading: false,
        error: null
    },
    appointments: {
        list: [],
        loading: false,
        error: null,
        rxSent: false
    },
    newsletterSubs: {
        list: [],
        loading: false,
        error: null
    },
};

const MarketingReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case APPOINTMENTS:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: false,
                    list: payload
                }
            }
        case TOGGLE_APPOINTMENTS_LOADING:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: !state.loading
                }
            }
        case RX_SENT:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    loading: false,
                    rxSent: !state.rxSent,
                }
            }
        case APPOINTMENTS_ERROR:
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    error: payload
                }
            }
        case CONTACTS:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    list: payload
                }
            }
        case CONTACTS_ERROR:
            return {
                ...state,
                contacts: {
                    ...state.contacts,
                    error: payload
                }
            }
        case NEWSLETTER_SUBS:
            return {
                ...state,
                newsletterSubs: {
                    ...state.newsletterSubs,
                    list: payload
                }
            }
        case NEWSLETTER_SUBS_ERROR:
            return {
                ...state,
                newsletterSubs: {
                    ...state.newsletterSubs,
                    error: payload
                }
            }
        default:
            return {...state};
    }
}

export default MarketingReducer;