export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CONTACTS = 'CONTACTS';
export const CONTACTS_ERROR = 'CONTACTS_ERROR';
export const APPOINTMENTS = 'APPOINTMENTS';
export const TOGGLE_APPOINTMENTS_LOADING = 'TOGGLE_APPOINTMENTS_LOADING';
export const RX_SENT = 'RX_SENT';
export const APPOINTMENTS_ERROR = 'APPOINTMENTS_ERROR';
export const NEWSLETTER_SUBS = 'NEWSLETTER_SUBS';
export const NEWSLETTER_SUBS_ERROR = 'NEWSLETTER_SUBS_ERROR';